import ValidatorClass from './ValidatorClass'

export default new ValidatorClass({
    Description: {
        name: 'Description',
        required: true,
        format: /(.*[\S]){5,}/,
        custom_message: 'Description must contain at least 5 characters',
    },

    PhoneNumber: {
        name: 'Phone number',
        required: true,
        replaces: [
            {
                format: /^\+?\d{0,4}\(?\d{1,4}\)?[-\s\d]*$/,
                pattern: /[^\d]/g,
                replace: '',
            },
        ],
        format: /^\+?\d{9,12}$/,
        custom_message: 'Please, enter valid phone number',
    },

    Note: {
        name: 'Note',
        required: true,
        custom_message: 'Please, write a note',
    },

    Username: {
        name: 'Username',
        required: true,
    },

    Password: {
        name: 'Password',
        required: true,
        customPasswordCheck: true,
    },

    IPAddress: {
        name: 'IPv4 address',
        required: true,
        format: /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
    },

    AccountNumber: {
        name: 'Account number',
        required: true,
    },

    SubaccountNumber: {
        name: 'Subaccount number',
        required: true,
        trim: true,
    },

    Address: {
        name: 'Address',
        required: true,
        trim: true,
    },

    NumberSIPChannels: {
        name: 'Number of SIP Channels',
        required: true,
        format: /^\d+$/,
    },
})
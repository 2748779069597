<template>
    <app-dialog-info
        class="app-dialog-phone-number-removal"
        v-if="show"
        v-model="show"
        :max-width="maxWidth"
    >
        <template #head>
            <h2 class="heading">Phone Number removal</h2>
        </template>

        <template #body>
            <app-loader v-if="loading"></app-loader>

            <div class="wrap">
                <app-error v-model="errors.show" :message="errors.message"></app-error>

                <div class="content">
                    <p>Are you sure you want to remove the number from the SIP Trunk.</p>
                    <p>If this was the action that you want to do, please confirm your choice or cancel and return to the page.</p>
                </div>

                <div class="btns">
                    <button class="btn btn-primary btn-left" @click="confirm">Confirm</button>
                    <button class="btn btn-primary btn-cancel btn-right" @click="close">Cancel</button>
                </div>
            </div>
        </template>
    </app-dialog-info>
</template>

<script>
import { mapGetters } from 'vuex'

import appDialogInfo from '@/components/app-dialog-info'
import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'

import errMessage from '@/helpers/errMessage'

export default {
    props: {
        value:       {                         required: true },
        sipTrunk:    { type: Object,           required: true },
        removeIndex: { type: Number,           required: true },
        maxWidth:    { type: [Number, String], default: 610   },
    },

    components: {
        appDialogInfo,
        appLoader,
        appError,
    },

    data() {
        return {
            loading: false,

            errors: {
                show: false,
                message: '',
            },
        }
    },

    methods: {
        close() {
            this.$emit('close')
        },

        confirm() {
            this.loading = true
            this.errors.show = false
            this.errors.message = ''

            const PhoneNumbers = [...this.sipTrunk.PhoneNumbers]
            PhoneNumbers.splice(this.removeIndex, 1)

            const params = {
                SPID: this.current_spid,
                UUID: this.sipTrunk.UUID,
                PhoneNumbers,
            }

            this.$store.dispatch('api_siptrunk/UpdateSIPTrunk', params)
                .then(response => this.$emit('success', response))
                .catch(error => {
                    this.errors.show = true
                    this.errors.message = errMessage(error)
                })
                .finally(() => this.loading = false)
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        show: {
            get() {
                return this.value
            },

            set(value) {
                this.$emit('input', value)

                if (value === false) {
                    this.close()
                }
            },
        },
    },
}
</script>

<style lang="scss">
.app-dialog.app-dialog-info.app-dialog-phone-number-removal {
    .app-dialog-body {
        .wrap {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }

        .content {
            margin-bottom: 40px;
        }

        p {
            font-weight: normal;
        }
    }
}

@media (max-width: $mobile-size) {
    .app-dialog.app-dialog-info.app-dialog-phone-number-removal {
        .app-dialog-body {
            .content {
                margin: 0;
            }
        }
    }
}
</style>
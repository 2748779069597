<template>
    <div id="page-sip-trunk" :class="{ 'on-sidebar': on_sidebar }">
        <app-loader v-if="loading" fixed-on="desktop" />

        <div class="container">
            <div class="row">
                <div class="col-12" v-if="!on_sidebar">
                    <router-link class="btn btn-back-to" :to="back_to">Back to SIP Trunks</router-link>

                    <h1 class="heading" v-if="is_edit_mode">Edit user details</h1>
                    <h1 class="heading" v-else>Add new user</h1>
                </div>

                <div class="col-6 col-tab-12">
                    <div class="board general-settings">
                        <h2 class="heading">General settings</h2>

                        <div class="form-group">
                            <div class="form-controls">
                                <app-checkbox
                                    v-model="sip_trunk.Enabled"
                                    :disabled="loading"
                                >Enabled</app-checkbox>

                                <div class="status">Registered: <span class="label" :class="[status.value]">{{ status.text }}</span></div>
                            </div>

                            <div class="form-controls">
                                <app-input
                                    v-model="sip_trunk.Username"

                                    label="Username"

                                    :error="errors.Username"
                                    :disabled="loading || is_edit_mode"

                                    @change="errors.Username = null"
                                />

                                <app-input
                                    v-model="sip_trunk.Password"

                                    type="password"
                                    label="Password"

                                    :error="errors.Password"
                                    :disabled="loading"
                                    :with-toggle-password-visibility="true"

                                    @change="errors.Password = null"
                                />

                                <app-textarea
                                    v-model="sip_trunk.Description"

                                    label="Description"

                                    :error="errors.Description"
                                    :disabled="loading"

                                    @change="errors.Description = null"
                                />

                                <app-input
                                    :value="sip_trunk.NumberSIPChannels"

                                    label="Number of SIP Channels"
                                    
                                    disabled
                                />
                            </div>

                            <!--
                                <div class="form-controls">
                                    <app-checkbox
                                        v-model="sip_trunk.Trunking"
                                        :disabled="loading"
                                    >Trunking</app-checkbox>

                                    <app-checkbox
                                        v-model="sip_trunk.MenuActive"
                                        :disabled="loading"
                                    >MenuActive</app-checkbox>
                                </div>
                            -->
                        </div>
                    </div>
                </div>

                <div class="col-6 col-tab-12">
                    <div class="board privacy">
                        <h2 class="heading">Privacy</h2>

                        <div class="form-group">
                            <div class="form-controls">
                                <app-checkbox
                                    v-model="sip_trunk.DoNotDisturb"
                                    :disabled="loading"
                                >Do Not Disturb</app-checkbox>

                                <!--
                                    <app-checkbox
                                        v-model="sip_trunk.Private"
                                        :disabled="loading"
                                    >Private (block CLID)</app-checkbox>
                                -->

                                <app-checkbox
                                    v-model="sip_trunk.BlockPrivateNumbers"
                                    :disabled="loading"
                                >Block Private Numbers</app-checkbox>

                                <app-checkbox
                                    v-model="sip_trunk.SendCallerID"
                                    :disabled="loading"
                                >Send Caller ID</app-checkbox>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-tab-12">
                    <div class="board forwarding">
                        <h2 class="heading">Forwarding</h2>

                        <div class="form-container">
                            <div class="form-section no-answer">
                                <h5 class="heading">No Answer</h5>
                                <div class="form-group">
                                    <div class="form-controls">
                                        <app-checkbox
                                            v-model="sip_trunk.CFNAEnabled"
                                            :disabled="loading"
                                        >Enabled</app-checkbox>
                                    </div>

                                    <div class="form-controls">
                                        <app-input
                                            v-model="sip_trunk.CFNARingTime"

                                            label="Ring Time (sec)"

                                            :error="errors.CFNARingTime"
                                            :disabled="!sip_trunk.CFNAEnabled || loading"

                                            @change="errors.CFNARingTime = null"
                                        />

                                        <app-input
                                            v-model="sip_trunk.CFNADestination"

                                            label="Destination"

                                            :error="errors.CFNADestination"
                                            :disabled="!sip_trunk.CFNAEnabled || loading"

                                            @change="errors.CFNADestination = null"
                                        />
                                    </div>
                                </div>
                            </div>

			<!--
                            <div class="form-section busy">
                                <h5 class="heading">Busy</h5>
                                <div class="form-group">
                                    <div class="form-controls">
                                        <app-checkbox
                                            v-model="sip_trunk.CFBEnabled"
                                            :disabled="loading"
                                        >Enabled</app-checkbox>

                                        <app-input
                                            v-model="sip_trunk.CFBDestination"

                                            label="Destination"

                                            :error="errors.CFBDestination"
                                            :disabled="!sip_trunk.CFBEnabled || loading"

                                            @change="errors.CFBDestination = null"
                                        />
                                    </div>
                                </div>
                            </div>
			-->

			<!--
                            <div class="form-section always">
                                <h5 class="heading">Always</h5>
                                <div class="form-group">
                                    <div class="form-controls">
                                        <app-checkbox
                                            v-model="sip_trunk.CFAEnabled"
                                            :disabled="loading"
                                        >Enabled</app-checkbox>

                                        <app-input
                                            v-model="sip_trunk.CFADestination"

                                            label="Destination"

                                            :error="errors.CFADestination"
                                            :disabled="!sip_trunk.CFAEnabled || loading"

                                            @change="errors.CFADestination = null"
                                        />
                                    </div>
                                </div>
                            </div>
			-->

                            <div class="form-section not-registered">
                                <h5 class="heading">Not Registered</h5>
                                <div class="form-group">
                                    <div class="form-controls">
                                        <app-checkbox
                                            v-model="sip_trunk.CFNREnabled"
                                            :disabled="loading"
                                        >Enabled</app-checkbox>

                                        <app-input
                                            v-model="sip_trunk.CFNRDestination"

                                            label="Destination"

                                            :error="errors.CFNRDestination"
                                            :disabled="!sip_trunk.CFNREnabled || loading"

                                            @change="errors.CFNRDestination = null"
                                        />
                                    </div>
                                </div>
                            </div>

                            <!--
                                <div class="form-section call-recording">
                                    <h5 class="heading">Call Recording</h5>
                                    <div class="form-group">
                                        <div class="form-controls">
                                            <app-checkbox
                                                v-model="sip_trunk.RecordInbound"
                                                :disabled="loading"
                                            >Record Inbound</app-checkbox>

                                            <app-input
                                                v-model="sip_trunk.RecordInboundAddress"

                                                label="Record Address"

                                                :error="errors.RecordInboundAddress"
                                                :disabled="loading"

                                                @change="errors.RecordInboundAddress = null"
                                            />
                                        </div>

                                        <div class="form-controls">
                                            <app-checkbox
                                                v-model="sip_trunk.RecordOutbound"
                                                :disabled="loading"
                                            >Record Outbound</app-checkbox>

                                            <app-input
                                                v-model="sip_trunk.RecordOutboundAddress"

                                                label="Record Address"

                                                :error="errors.RecordOutboundAddress"
                                                :disabled="loading"

                                                @change="errors.RecordOutboundAddress = null"
                                            />
                                        </div>
                                    </div>
                                </div>
                            -->

                            <div class="form-section phone-numbers">
                                <h5 class="heading" :class="{ 'has-phone-numbers': has_phone_numbers }">Phone Numbers</h5>

                                <div class="phone-numbers-list" v-if="has_phone_numbers">
                                    <div class="phone-number" v-for="(phone_number, index) in sip_trunk.PhoneNumbers" :key="index">
                                        <span class="value">{{ phone_number }}</span>

                                        <div class="actions">
                                            <button class="btn btn-table-action" :class="[ isPilotNumber(phone_number) ? 'pilot' : 'make-pilot' ]" :disabled="loading" @click="togglePilot(phone_number)"></button>
                                            <button class="btn btn-table-action action-secondary action-remove" :disabled="loading" @click="onRemovePhoneNumber(index)"></button>
                                        </div>
                                    </div>
                                </div>
			<!--
                                <div class="form-group">
                                    <div class="form-controls">
                                        <app-input
                                            v-model="phone_number"

                                            label="Add new number"

                                            :mask="phone_number_mask"
                                            :error="errors.phone_number"
                                            :disabled="loading"

                                            @change="errors.phone_number = null"
                                            @onenter="addPhoneNumber"
                                        />

                                        <button class="btn btn-primary btn-add-number" @click="addPhoneNumber">Add</button>
                                    </div>
                                </div>
                            </div>
			-->
                                <div class="form-section sip-destination">
                                    <h5 class="heading">SIP Destination</h5>
                                    <div class="form-group">
                                        <div class="form-controls">
                                            <app-input
                                                v-model="sip_trunk.EndpointIPAddress"

                                                label="IP Address"

                                                :error="errors.EndpointIPAddress"
                                                :disabled="loading"

                                                @change="errors.EndpointIPAddress = null"
                                            />

                                            <app-input
                                                v-model="sip_trunk.EndpointDomain"

                                                label="Domain"

                                                :error="errors.EndpointDomain"
                                                :disabled="loading"

                                                @change="errors.EndpointDomain = null"
                                            />

                                            <app-input
                                                v-model="sip_trunk.EndpointUsername"

                                                label="Username"

                                                :error="errors.EndpointUsername"
                                                :disabled="loading"

                                                @change="errors.EndpointUsername = null"
                                            />

                                            <app-input
                                                v-model="sip_trunk.EndpointPassword"

                                                label="Password"

                                                :error="errors.EndpointPassword"
                                                :disabled="loading"

                                                @change="errors.EndpointPassword = null"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <app-error v-model="errors.save"></app-error>

                        <button class="btn btn-primary btn-save" :disabled="loading || !is_changed" @click="save">Save</button>
                    </div>
                </div>
            </div>
        </div>

        <app-dialog-phone-number-removal
            v-if="dialogs.phone_number.remove.show && dialogs.phone_number.remove.index != null"
            v-model="dialogs.phone_number.remove.show"

            :sip-trunk="prepareData(original_sip_trunk)"
            :remove-index="dialogs.phone_number.remove.index"

            @success="onDialogPhoneNumberRemovalSuccess"
            @close="onDialogPhoneNumberRemovalClose"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'
import appInput from '@/components/app-input'
import appTextarea from '@/components/app-textarea'
import appCheckbox from '@/components/app-checkbox'

import errMessage from '@/helpers/errMessage'

import appDialogPhoneNumberRemoval from './components/app-dialog-phone-number-removal'

import SipTrunkValidator from '@/validators/sip-trunk-validator'

const COUNTRY_CODE = '+64'

const EMPTY_SIP_TRUNK = {
    Enabled: false,
    Username: '',
    Registered: false,
    Password: '',
    Description: '',
    PilotNumber: '',
    Trunking: false,
    MenuActive: false,
    DoNotDisturb: false,
    Private: false,
    BlockPrivateNumbers: false,
    SendCallerID: false,
    CFNAEnabled: false,
    CFNARingTime: '',
    CFNADestination: '',
    CFBEnabled: false,
    CFBDestination: '',
    CFAEnabled: false,
    CFADestination: '',
    CFNREnabled: false,
    CFNRDestination: '',
    RecordInbound: false,
    RecordInboundAddress: '',
    RecordOutbound: false,
    RecordOutboundAddress: '',
    PhoneNumbers: [],
    EndpointIPAddress: '',
    EndpointDomain: '',
    EndpointUsername: '',
    EndpointPassword: '',
}

export default {
    props: {
        uuid: { type: String, default: null },
    },

    components: {
        appLoader,
        appError,
        appInput,
        appTextarea,
        appCheckbox,
        appDialogPhoneNumberRemoval,
    },

    data() {
        return {
            sip_trunk: {
                // GENERAL SETTINGS
                Enabled: false, // Enabled
                Registered: false, // Registered
                Username: '', // Username
                Password: '', // Password
                Description: '', // Description
                Trunking: false, // Trunking
                MenuActive: false, // MenuActive

                // PRIVACY
                DoNotDisturb: false, // Do Not Disturb
                Private: false, // Private (block CLID)
                BlockPrivateNumbers: false, // Block Private Numbers
                SendCallerID: false, // Send Caller ID

                // FORWARDING
                PilotNumber: '',

                // CFNA ---> No Answer
                CFNAEnabled: false, // Enabled
                CFNARingTime: '', // Ring Time
                CFNADestination: '', // Destination

                // CFB ---> Busy
                CFBEnabled: false, // Enabled
                CFBDestination: '', // Destination

                // CFA ---> Always
                CFAEnabled: false, // Enabled
                CFADestination: '', // Destination

                // CFNR ---> Not Registered
                CFNREnabled: false, // Enabled
                CFNRDestination: '', // Destination

                // CALL RECORDING
                RecordInbound: false, // Record Inbound
                RecordInboundAddress: '', // Record Address
                RecordOutbound: false, // Record Outbound
                RecordOutboundAddress: '', // Record Address

                // PHONE NUMBERS
                PhoneNumbers: [], // Add new number

                // SIP DESTINATION
                EndpointIPAddress: '', // IP Address
                EndpointDomain: '', // Domain
                EndpointUsername: '', // Username
                EndpointPassword: '' // Password
            },
            original_sip_trunk: JSON.parse(JSON.stringify(EMPTY_SIP_TRUNK)),

            phone_number: COUNTRY_CODE,
            /**
             * When your new mask is acting strange and replaces some static chars with the mask,
             * then there is a definition that uses the char as a symbol.
             * To solve this you need to double escape the char.
             */
            phone_number_mask: `${COUNTRY_CODE}999999999`,

            loading: false,

            referrer: null,
            on_sidebar: false,

            errors: {},

            dialogs: {
              phone_number: {
                remove: {
                  show: false,
                  index: null,
                },
              },
            },
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.on_sidebar = this.$parent.$el.classList.contains('app-sidebar-details')
    },

    methods: {
        init() {
            if (this.is_edit_mode) {
                this.getSIPTrunk()
            }
        },

        getSIPTrunk() {
            this.loading = true

            const params = {
                SPID: this.current_spid,
                UUID: this.sip_trunk_uuid,
            }

            this.$store.dispatch('api_siptrunk/GetSIPTrunkByUUID', params)
                .then(response => {
                    this.original_sip_trunk = JSON.parse(JSON.stringify(response))
                    this.sip_trunk          = JSON.parse(JSON.stringify(response))

                    this.loading = false
                })
                .catch(error => {
                    this.loading = false

                    if (this.on_sidebar) {
                        this.$emit('close-sidebar')
                    } else {
                        this.$router.push({
                            ...this.back_to,
                            replace: true,
                        })
                    }

                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })
                })
        },

        prepareData(sip_trunk) {
            const data = { ...sip_trunk }

            delete data.CreatedAtNanos
            delete data.UpdatedAtNanos
            delete data.DeletedAtNanos

            // data.CFNARingTime = parseInt(data.CFNARingTime, 10)
            data.CFNARingTime = +data.CFNARingTime

            return data
        },

        validation() {
            let is_valid = true

            this.errors = {}

            const fields = {
                Password: { rule: 'Password', message: 'Please, enter password' },

                EndpointIPAddress: { rule: 'IPAddress', message: 'Please, enter valid IPv4 address', },
            }

            for (const key in fields) {
                if (SipTrunkValidator.isRuleExists(fields[key].rule)) {
                    if (SipTrunkValidator.isInvalid(fields[key].rule, this.sip_trunk[key], fields[key].message)) {
                        this.errors[key] = SipTrunkValidator.getError()
                        is_valid = false
                    }
                }
            }

            return is_valid
        },

        save() {
            if (this.validation()) {
                this.loading = true

                const action = this.is_edit_mode ? 'api_siptrunk/UpdateSIPTrunk' : 'api_siptrunk/AddSIPTrunk'

                const params = {
                    SPID: this.current_spid,
                    ...this.prepareData(this.sip_trunk),
                }

                this.$store.dispatch(action, params)
                    .then(response => {
                        this.original_sip_trunk = JSON.parse(JSON.stringify(response))
                        this.sip_trunk          = JSON.parse(JSON.stringify(response))

                        this.errors = {}
                        this.loading = false
                        this.handleSuccess()
                    })
                    .catch(error => {
                        this.errors.save = errMessage(error)
                        this.loading = false
                    })
            }
        },

        reset() {
            this.errors = {}
            this.phone_number = COUNTRY_CODE

            if (!this.is_edit_mode) {
                this.original_sip_trunk = JSON.parse(JSON.stringify(EMPTY_SIP_TRUNK))
            }

            this.sip_trunk = JSON.parse(JSON.stringify(this.original_sip_trunk))
        },

        addPhoneNumber() {
            const rule = 'PhoneNumber'
            const error = SipTrunkValidator.isInvalid(rule, this.phone_number)

            if (!error) {
                this.sip_trunk.PhoneNumbers.push(this.phone_number)
                this.phone_number = COUNTRY_CODE
            }
        },

        onRemovePhoneNumber(index) {
            this.dialogs.phone_number.remove.index = index
            this.dialogs.phone_number.remove.show = true
        },

        onDialogPhoneNumberRemovalSuccess(response) {
            const phone_number_removed = this.sip_trunk.PhoneNumbers[ this.dialogs.phone_number.remove.index ]

            if (this.sip_trunk.PilotNumber == phone_number_removed) {
                this.sip_trunk.PilotNumber = ''
            }

            this.original_sip_trunk = JSON.parse(JSON.stringify(response))
            this.sip_trunk.PhoneNumbers = this.original_sip_trunk.PhoneNumbers

            this.dialogs.phone_number.remove.index = null
            this.dialogs.phone_number.remove.show = false
        },

        onDialogPhoneNumberRemovalClose() {
            this.dialogs.phone_number.remove.index = null
            this.dialogs.phone_number.remove.show = false
        },

        removePhoneNumber(index) {
            const phone_number_to_remove = this.sip_trunk.PhoneNumbers[index]

            if (this.sip_trunk.PilotNumber == phone_number_to_remove) {
                this.sip_trunk.PilotNumber = ''
            }

            this.sip_trunk.PhoneNumbers.splice(index, 1)
        },

        togglePilot(phone_number) {
            if (this.sip_trunk.PilotNumber == phone_number) {
                this.sip_trunk.PilotNumber = ''
            } else {
                this.sip_trunk.PilotNumber = phone_number
            }
        },

        isPilotNumber(phone_number) {
            return this.sip_trunk.PilotNumber == phone_number
        },

        handleSuccess() {
            if (this.on_sidebar) {
                this.$emit('close-sidebar')
                this.$emit('saved')
            } else {
                this.$router.push({
                    ...this.back_to,
                    replace: true,
                })
            }
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
        ]),

        back_to() {
            return this.referrer && this.referrer.name == 'sip-trunking-sip-trunks'
                ? this.referrer
                : { name: 'sip-trunking-sip-trunks' }
        },

        sip_trunk_uuid() {
            return this.uuid || this.$route.params.uuid
        },

        is_edit_mode() {
            return Boolean(this.sip_trunk_uuid)
        },

        status() {
            return this.sip_trunk.Registered
                ? { value: 'success', text: 'Registered',     }
                : { value: 'danger',  text: 'Not registered', }
        },

        has_phone_numbers() {
            return this.sip_trunk.PhoneNumbers.length > 0
        },

        is_changed() {
            let is_changed = false

            for (const key in EMPTY_SIP_TRUNK) {
                if (Array.isArray(this.sip_trunk[key])) {
                    if (this.sip_trunk[key].length != this.original_sip_trunk[key].length) {
                        is_changed = true
                    } else {
                        for (let i = 0, len = this.sip_trunk[key].length; i < len; i++) {
                            if (this.sip_trunk[key][i] != this.original_sip_trunk[key][i]) {
                                is_changed = true
                                break
                            }
                        }
                    }
                } else {
                    is_changed = this.sip_trunk[key] != this.original_sip_trunk[key]
                }

                if (is_changed) {
                    break
                }
            }

            return is_changed
        },
    },

    watch: {
        uuid() {
            if (this.is_edit_mode) {
                this.getSIPTrunk()
            } else {
                this.reset()
            }
        },
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.referrer = from
        })
    },
}
</script>

<style lang="scss">
#page-sip-trunk {
    margin: 24px 0 80px;

    .btn-back-to {
        margin-bottom: 12px;
    }

    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .app-error {
        margin-bottom: 24px;
    }

    .board {
        margin-bottom: 30px;
        padding: 24px;

        background-color: var(--color-component-bg-primary);
        border-radius: $border-radius-primary;
        box-shadow: var(--box-shadow-secondary);

        h2 {
            &.heading {
                margin-bottom: 24px;
            }
        }

        &.general-settings {
            padding-bottom: 30px;

            .form-group {
                .form-controls {
                    &:nth-child(1) {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        margin-bottom: 24px;

                        .status {
                            display: flex;
                            align-items: center;
                            font-size: 16px;
                            line-height: 24px;

                            .label {
                                margin-left: 12px;
                                padding: 4px 12px;
                                font-weight: bold;
                                text-align: center;
                                border-radius: $border-radius-primary;

                                @each $type in $table-tag-types {
                                    &.#{ $type } {
                                        color: map-get($table-tag-colors, $type);
                                        background-color: map-get($table-tag-backgrounds, $type);
                                    }
                                }
                            }
                        }
                    }

                    &:nth-child(2) {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;

                        // margin-bottom: 30px;

                        .app-input {
                            max-width: calc(50% - 15px);
                        }

                        .app-textarea {
                            height: 96px;
                        }

                        &>* {
                            margin-bottom: 30px;

                            &:last-child { margin-bottom: 0; }
                        }
                    }

                    &:nth-child(3) {
                        display: flex;
                        flex-wrap: wrap;

                        .app-checkbox {
                            margin-right: 80px;

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }

        &.privacy {
            padding-bottom: 30px;

            .form-group {
                .form-controls {
                    display: flex;
                    flex-wrap: wrap;

                    margin-bottom: -24px;
                    padding-top: 4px;

                    .app-checkbox {
                        margin-right: 40px;
                        margin-bottom: 24px;
                    }
                }
            }
        }

        &.forwarding {
            .form-container {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                margin-bottom: -40px;

                .form-section {
                    width: 100%;
                    max-width: calc(50% - 30px);

                    margin-bottom: 40px;

                    h5 {
                        &.heading {
                            margin-bottom: 24px;
                        }
                    }

                    .form-group {
                        .form-controls {
                            .app-checkbox {
                                margin-bottom: 24px;
                            }
                        }
                    }

                    &.no-answer {
                        .form-group {
                            .form-controls {
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: space-between;

                                .app-input {
                                    &:nth-child(1) {
                                        max-width: calc(40% - 15px);
                                    }

                                    &:nth-child(2) {
                                        max-width: calc(60% - 15px);
                                    }
                                }
                            }
                        }
                    }

                    &.call-recording {
                        max-width: 100%;

                        .form-group {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;

                            .form-controls {
                                width: 100%;
                                max-width: calc(50% - 30px);
                            }
                        }
                    }

                    &.phone-numbers {
                        h5 {
                            &.heading {
                                &.has-phone-numbers {
                                    margin-bottom: 8px;
                                }
                            }
                        }

                        .phone-numbers-list {
                            margin-bottom: 24px;

                            font-size: 16px;
                            line-height: 24px;

                            .phone-number {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                padding: 16px 0;

                                border-bottom: 1px solid var(--color-divider);

                                .actions {
                                    display: flex;

                                    .btn {
                                        margin-right: 24px;

                                        &.make-pilot,
                                        &.pilot {
                                            color: var(--color-table-action-secondary-hover);
                                        }

                                        &.make-pilot {
                                            @include icon-before-code($icon-make-pilot);
                                        }

                                        &.pilot {
                                            @include icon-before-code($icon-pilot);
                                        }

                                        &:last-child {
                                            margin-right: 0;
                                        }
                                    }
                                }
                            }
                        }

                        .form-group {
                            .form-controls {
                                $btn-max-width: 173px;

                                display: flex;
                                flex-wrap: wrap;
                                justify-content: space-between;

                                .app-input {
                                    max-width: calc(100% - #{ $btn-max-width } - 30px);
                                }

                                .btn-add-number {
                                    max-width: $btn-max-width;
                                }
                            }
                        }
                    }

                    &.sip-destination {
                        .form-group {
                            .form-controls {
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: space-between;

                                margin-bottom: -30px;

                                .app-input {
                                    margin-bottom: 30px;

                                    &:nth-child(3),
                                    &:nth-child(4) {
                                        max-width: calc(50% - 15px);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.on-sidebar {
        margin: 40px 0 32px;

        .container {
            .row {
                .col-6 {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }

        .board {
            h2 {
                &.heading {
                    font-size: 24px;
                    line-height: 32px;
                }
            }

            &.general-settings {
                .form-group {
                    .form-controls {
                        &:nth-child(2) {
                            .app-input {
                                max-width: 100%;
                            }
                        }
                    }
                }
            }

            &.forwarding {
                .form-container {
                    .form-section {
                        max-width: 100%;

                        &.call-recording {
                            .form-group {
                                .form-controls {
                                    max-width: 100%;

                                    margin-bottom: 24px;

                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }

                        &.sip-destination {
                            .form-group {
                                .form-controls {
                                    .app-input {
                                        &:nth-child(3),
                                        &:nth-child(4) {
                                            max-width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        button {
            &.btn-save {
                max-width: 100%;
            }
        }
    }
}

@media (max-width: $tablet-size) {
    #page-sip-trunk {
        .board {
            &.forwarding {
                .form-container {
                    .form-section {
                        max-width: 100%;

                        &.call-recording {
                            .form-group {
                                .form-controls {
                                    max-width: calc(50% - 15px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    #page-sip-trunk {
        .board {
            margin-bottom: 16px;
            padding: 16px 8px;

            &.general-settings {
                padding-bottom: 16px;

                .form-group {
                    .form-controls {
                        &:nth-child(1) {
                            .status {
                                font-size: 14px;
    
                                .label {
                                    margin-left: 8px;
                                    padding: 2px 8px;
    
                                    font-size: 12px;
                                    line-height: 20px;
                                }
                            }
                        }

                        &:nth-child(2) {
                            margin-bottom: 24px;

                            .app-input {
                                max-width: 100%;
                            }

                            &>* {
                                margin-bottom: 24px;

                                &:last-child { margin-bottom: 0; }
                            }
                        }
                    }
                }
            }

            &.privacy {
                padding-bottom: 16px;

                .form-group {
                    .form-controls {
                        .app-checkbox {
                            margin-right: 32px;
                        }
                    }
                }
            }

            &.forwarding {
                .form-container {
                    margin-bottom: -30px;

                    .form-section {
                        margin-bottom: 30px;

                        &.no-answer {
                            .form-group {
                                .form-controls {
                                    .app-input {
                                        &:nth-child(1),
                                        &:nth-child(2) {
                                            max-width: 100%;

                                            margin-bottom: 24px;
                                        }

                                        &:last-child {
                                            margin-bottom: 0;
                                        }
                                    }
                                }
                            }
                        }

                        &.call-recording {
                            .form-group {
                                .form-controls {
                                    max-width: 100%;

                                    margin-bottom: 24px;

                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }

                        &.phone-numbers {
                            .phone-numbers-list {
                                font-size: 14px;

                                .phone-number {
                                    padding: 8px 0;

                                    .actions {
                                        .btn {
                                            margin-right: 16px;
                                        }
                                    }
                                }
                            }

                            .form-group {
                                .form-controls {
                                    .app-input,
                                    .btn-add-number {
                                        max-width: 100%;
                                    }

                                    .app-input {
                                        margin-bottom: 24px;
                                    }
                                }
                            }
                        }

                        &.sip-destination {
                            .form-group {
                                .form-controls {
                                    .app-input {
                                        margin-bottom: 24px;

                                        &:nth-child(3),
                                        &:nth-child(4) {
                                            max-width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        button {
            &.btn-save {
                max-width: 100%;
            }
        }
    }
}
</style>

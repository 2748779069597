<template>
    <label class="app-checkbox"
        :for="id"
        :class="{ checked: value, 'partially-checked': partiallyChecked, disabled, readonly }"

        @click.stop
    >
        <span><slot></slot></span>
        <input type="checkbox"
            :id="id"
            :checked="value"
            :disabled="disabled"
            :readonly="readonly"

            @input="onChange"
            @change="onChange"
        >
    </label>
</template>

<script>
export default {
    props: {
        value: { required: true },

        disabled: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },

        partiallyChecked: { type: Boolean, default: false },
    },

    methods: {
        onChange(event) {
            if (!this.readonly) {
                this.$emit('input', event.target.checked)

                if (event.target.checked != this.value) {
                    this.$emit('change', event.target.checked)
                }
            }
        },
    },

    computed: {
        id: function() {
            return 'app-chbx-' + this._uid
        },
    },
}
</script>

<style lang="scss">
.app-checkbox {
    width: fit-content;
    display: flex;
    justify-content: flex-start;
    font-size: 16px;
    line-height: 24px;
    user-select: none;
    cursor: pointer;

    input[type=checkbox] {
        display: none;
    }

    &:before {
        flex-shrink: 0;
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-right: 2px;

        color: var(--color-btn-primary-bg);

        @include icon($icon-checkbox, 24px);
    }

    &.checked {
        &:before {
            @include icon($icon-checkbox-checked, 24px);
        }
    }

    &.partially-checked {
        &:before {
            @include icon($icon-checkbox-partially-checked, 24px);
        }
    }

    &:not(.readonly):not(.disabled) {
        &:hover {
            &:before {
                color: var(--color-btn-primary-bg-hover);
            }
        }
    }

    &.disabled {
        opacity: .4;
        cursor: default;
    }

    &.readonly {
        cursor: default;
    }
}

@media (max-width: $tablet-size) {}

@media (max-width: $mobile-size) {
    .app-checkbox {
        font-size: 14px;
    }
}
</style>